import "./recoverPasswordView.scss";
import { Button, Form } from "react-bootstrap";
import { useState } from "react";
import axios from "axios";
import logoDibba from "../../assets/images/logo-dibba-business.png"

export const RecoverPasswordView = (_) => {
  const [email, setEmail] = useState("");

  function postRecover(email) {
    if (!email) {
      return Error;
    } else {
      axios
        .post("https://api.dibba.com.br/users/password/reset", {
          emailAddress: email,
        })
        .then(({ data }) => {
          console.log(data);
          alert("Confira a mensagem no seu email")
        });
    }
  }

  return (
    <main className="recover-password-page">
      <div className="form-recover text-center">
          <img src={logoDibba} alt="" className="w-100" />
          <Form.Control
            type="text"
            className="mt-5 w-75 mx-auto"
            placeholder="Insira seu email aqui"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <Button
            className="mt-3 w-75 recover-password"
            onClick={() => {
              postRecover(email);
            }}
          >
            Recuperar Senha
          </Button>
      </div>
    </main>
  );
};
