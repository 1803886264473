import "./signUpView.scss";
import { useState } from "react";
import { Button, FloatingLabel, Form } from "react-bootstrap";
import axios from "axios";
import { useUserContext } from "../../contexts/auth";

export const SignUpView = (_) => {
  const { setToken, setLoggedStatus } = useUserContext();
  const [firstName, setFirstName] = useState();
  const [phone, setPhone] = useState();
  const [emailAddress, setEmailAddress] = useState();
  const [password, setPassword] = useState();
  const [docNumber, setDocNumber] = useState();
  const [errMessage, setErrMessage] = useState("");

  function handleSubmit() {
    axios
      .post("https://api.dibba.com.br/users", {
        firstName,
        lastName: "B",
        phone,
        emailAddress,
        password,
        docNumber,
        birthDate: new Date(),
        role: "COMPANY",
      })
      .then((res) => {
        /* setUser(res.data); */
        setToken(res.jwtToken);
        localStorage.setItem("loggedStatusVariable", "true");
        setLoggedStatus(true);
        // localStorage.setItem("user", JSON.stringify(res.data));
        window.location.assign("/");
      })
      .catch((err) => {
        console.log(err);
        setErrMessage(err.response.data.message);
      });
  }

  return (
    <main className="sign-up overflow-hidden d-flex justify-content-center align-items-center">
      <div className="background-auth d-flex justify-content-start align-items-center m-0 rounded-5">
        <form id="form-login" className="text-center d-flex flex-column">
          <h1 className="mb-4">Faça o cadastro de sua empresa!</h1>

          <FloatingLabel label="Nome da Empresa" className="mb-2">
            <Form.Control
              defaultValue={firstName}
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
              type="text"
              placeholder="Nome da Empresa"
              required
            />
          </FloatingLabel>

          <FloatingLabel label="CNPJ" className="mb-2">
            <Form.Control
              defaultValue={docNumber}
              onChange={(e) => {
                setDocNumber(e.target.value);
              }}
              type="text"
              placeholder="CNPJ"
              required
            />
          </FloatingLabel>

          <FloatingLabel label="Contato (email)" className="mb-2">
            <Form.Control
              defaultValue={emailAddress}
              onChange={(e) => {
                setEmailAddress(e.target.value);
              }}
              type="text"
              placeholder="Contato (email)"
              id="name"
              required
            />
          </FloatingLabel>

          <FloatingLabel label="Contato (telefone)" className="mb-2">
            <Form.Control
              defaultValue={phone}
              onChange={(e) => {
                setPhone(e.target.value);
              }}
              type="text"
              placeholder="Contato (telefone)"
              required
            />
          </FloatingLabel>

          <FloatingLabel label="Senha" className="mb-2">
            <Form.Control
              defaultValue={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              type="password"
              placeholder="Senha"
              required
            />
          </FloatingLabel>

          <Button
            variant={"dark"}
            className="mt-2 mb-3 border-0"
            id="btn-signup"
            onClick={() => {
              handleSubmit();
            }}
          >
            Criar conta
          </Button>

          {errMessage && (
            <p className="text-danger font-weight-bolder mt-3">{errMessage}</p>
          )}

          <small>
            <a href="/" className="text-dark">Já tem cadastro?</a>
          </small>
        </form>
      </div>
    </main>
  );
};
