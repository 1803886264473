// eslint react-hooks/exhaustive-deps 2
import { Col, Form, Row } from "react-bootstrap";

export const CabinsComponent = ({ position, cabins, setCabins }) => {
  return (
    <Row className="mt-4">
      <h5>Ingressos por Área</h5>

      <Col className="mt-3">
        <label htmlFor="eventCabinName" className="mb-1">
          Area do Evento *
        </label>

        <Form.Control
          defaultValue={cabins[position].name ? cabins[position].name : ""}
          onChange={(e) => {
            let newCabinsState = cabins;
            newCabinsState[position] = {
              ...newCabinsState[position],
              name: e.target.value,
            };

            setCabins(newCabinsState);
          }}
          type="text"
          placeholder="Ex: Camarote VIP"
        />
      </Col>

      <Col className="mt-3">
        <label htmlFor="ticketPrice" className="mb-1">
          Valor da Área *
        </label>

        <Form.Control
          type="number"
          placeholder="Ex: R$ 500,00"
          defaultValue={
            cabins[position].unitPrice ? cabins[position].unitPrice : ""
          }
          onChange={(e) => {
            let newCabinsState = cabins;
            newCabinsState[position] = {
              ...newCabinsState[position],
              unitPrice: parseFloat(e.target.value),
            };

            setCabins(newCabinsState);
          }}
        />
      </Col>

      <Col className="mt-3">
        <label htmlFor="ticketQuantity" className="mb-1">
          Quantidade de Ingressos por Área *
        </label>

        <Form.Control
          defaultValue={
            cabins[position].ticketQuantity
              ? cabins[position].ticketQuantity
              : ""
          }
          onChange={(e) => {
            let newCabinsState = cabins;
            newCabinsState[position] = {
              ...newCabinsState[position],
              ticketQuantity: parseInt(e.target.value),
            };

            setCabins(newCabinsState);
          }}
          type="number"
          placeholder="Ex: 10 ingressos"
        />
      </Col>
    </Row>
  );
};
