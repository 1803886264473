import "./footerComponent.scss";
import InstaIcon from "../../assets/images/insta-icon.svg";
import TwitterIcon from "../../assets/images/twitter-icon.svg";
import LinkedinIcon from "../../assets/images/linkedin-icon.svg";
import FaceIcon from "../../assets/images/face-icon.svg";

export const FooterComponent = (_) => {
  return (
    <footer className="footer text-light pt-5 text-center">
      <hr />
      <div className="w-100 text-center m-auto">
        <h5>Dibba Business</h5>
        <div className="social-icons py-2 d-flex gap-2 justify-content-center">
          <img
            alt={"instagram"}
            src={InstaIcon}
            href="https://instagram.com/dibbabr/"
          />
          <img alt={"facebook"} src={FaceIcon} />
          <img alt={"twitter"} src={TwitterIcon} />
          <img alt={"linkedin"} src={LinkedinIcon} />
        </div>
      </div>
      <small className="d-block pt-2 pb-3 text-light">
        Copyright © Dibba Business. 2022 All rights reserved
      </small>
    </footer>
  );
};
