import React, {
  createContext,
  useContext,
  useState,
} from "react";

export const UserContext = createContext({});

export const useUserContext = () => {
  return useContext(UserContext);
};

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [token, setToken] = useState(sessionStorage.getItem("tk"));
  const status = localStorage.getItem("loggedStatusVariable");
  const [loggedStatus, setLoggedStatus] = useState(!!status);

  return (
    <UserContext.Provider
      value={{
        loggedStatus,
        setLoggedStatus,
        user,
        setUser,
        token,
        setToken
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
