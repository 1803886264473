import "./headerComponent.scss";
import { Container, Image, Nav, Navbar } from "react-bootstrap";
import IconDibba from "../../assets/images/dibba-icon.png";
/* import { useUserContext } from "../../contexts/auth"; */
/* 
  const { setLoggedStatus } = useUserContext();

  <NavDropdown.Item
    onClick={() => {
      localStorage.clear();
      sessionStorage.clear();
      setLoggedStatus(false);
    }}
  >
    Sair
  </NavDropdown.Item>; 
*/

export const HeaderComponent = (_) => {
  const paths = [
    {
      href: "/event/new",
      text: "Criar evento",
    },
    {
      href: "/onboarding",
      text: "Onboarding",
    },
    {
      href: "/account",
      text: "Perfil",
    },
    {
      href: "https://dibba.com.br/",
      text: "Dibba Costumer",
    },
  ];

  return (
    <Navbar bg="transparent" variant="dark" expand="md">
      <Container>
        <Navbar.Brand href="/#" className="d-flex align-items-center justify-content-center gap-2">
          <Image src={IconDibba} width={40} /> Dibba Business
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" className="toggle-button" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mx-auto">
            {paths.map((path, i) => (
              <Nav.Link
                href={path.href}
                target={path.href === "https://dibba.com.br/" ? "_blank" : ''}
                key={i}
                active={window.location.pathname === path.href}
              >
                {path.text}
              </Nav.Link>
            ))}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
