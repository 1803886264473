import "./newEventSuccessView.scss";
import { Button } from "react-bootstrap";
import { AiOutlineCheckCircle } from "react-icons/ai"

export const NewEventSuccessView = (_) => {
  return (
    <main className="new-event-success-page text-light">
      <AiOutlineCheckCircle size={250} className="mb-4" />
      <h5>Parábens, seu evento foi criado com sucesso!</h5>
      <Button onClick={() => window.location.assign("/")} className="home-back mt-5 border-0">Voltar para pagina inicial</Button>
    </main>
  );
};
