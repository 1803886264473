import { useState } from "react";
import { Col, FloatingLabel, Form, Row, Button } from "react-bootstrap";
import { CabinsComponent } from "./event-areas/cabinsComponent";

export const StepTwo = ({
  genderTicket,
  setGenderTicket,
  ageClassification,
  setAgeClassification,
  ticketsCategory,
  setTicketsCategory,
  tag,
  setTag,
  spaces,
  setSpaces,
  cabins,
  setCabins,
}) => {
  const personalGenderOptions = ["Unissex", "Feminino e Masculino"];
  const ticketCategoryOptions = ["Individuais", "Áreas", "Ambos"];
  const ageClassificationOptions = [18, 17, 16, 15, 14];

  const [allotmentsCreatedQtty, setAllotmentsCreatedQtty] = useState(1);

  function renderSpaceSection() {
    function handleUpdateFieldValue(
      keyValue = {},
      spacePosition = 0,
      allotmentPosition = 0
    ) {
      setSpaces((prev) => {
        let prevSpaces = prev;
        prevSpaces[spacePosition].allotments[allotmentPosition] = {
          ...prevSpaces[spacePosition].allotments[allotmentPosition],
          ...keyValue,
        };

        return [...prevSpaces];
      });
    }

    function renderUnissexSpaceComponent(space = {}, allotmentIndex) {
      return (
        <Row>
          <Col className="mt-3">
            <label htmlFor="ticketPriceUnissex" className="mb-1">
              Valor do Ingresso Unissex *
            </label>
            <Form.Control
              defaultValue={space.allotments[allotmentIndex].ticketPriceUnisex}
              onChange={(e) =>
                handleUpdateFieldValue(
                  { ticketPriceUnisex: parseFloat(e.target.value) },
                  space._index,
                  allotmentIndex
                )
              }
              type="number"
              placeholder="Ex: R$ 65,00"
            />
          </Col>

          <Col className="mt-3">
            <label htmlFor="ticketQuantityUnisex" className="mb-1">
              Quantidade de Ingressos Unissex *
            </label>

            <Form.Control
              defaultValue={
                space.allotments[allotmentIndex].ticketQuantityUnisex
              }
              onChange={(e) =>
                handleUpdateFieldValue(
                  { ticketQuantityUnisex: parseInt(e.target.value) },
                  space._index,
                  allotmentIndex
                )
              }
              type="number"
              placeholder="Ex: 100 ingressos"
            />
          </Col>
        </Row>
      );
    }

    function renderDiffValuesSpaceComponent(space = {}, allotmentIndex) {
      return (
        <Row>
          <Col className="mt-3">
            <label htmlFor="ticketPriceMale" className="mb-1">
              Valor do Ingresso Masculino *
            </label>
            <Form.Control
              defaultValue={space.allotments[allotmentIndex].ticketPriceMale}
              onChange={(e) =>
                handleUpdateFieldValue(
                  { ticketPriceMale: parseFloat(e.target.value) },
                  space._index,
                  allotmentIndex
                )
              }
              type="number"
              placeholder="Ex: R$ 87,00"
            />
          </Col>
          <Col className="mt-3">
            <label htmlFor="ticketPriceFemale" className="mb-1">
              Valor do Ingresso Feminino *
            </label>
            <Form.Control
              defaultValue={space.allotments[allotmentIndex].ticketPriceFemale}
              onChange={(e) =>
                handleUpdateFieldValue(
                  { ticketPriceFemale: parseFloat(e.target.value) },
                  space._index,
                  allotmentIndex
                )
              }
              type="number"
              placeholder="Ex: R$ 65,00"
            />
          </Col>

          <Col className="mt-3">
            <label htmlFor="ticketQuantityMale" className="mb-1">
              Quantidade de Ingressos Masculinos *
            </label>

            <Form.Control
              defaultValue={space.allotments[allotmentIndex].ticketQuantityMale}
              onChange={(e) =>
                handleUpdateFieldValue(
                  { ticketQuantityMale: parseInt(e.target.value) },
                  space._index,
                  allotmentIndex
                )
              }
              type="number"
              placeholder="Ex: 100 ingressos"
            />
          </Col>

          <Col className="mt-3">
            <label htmlFor="ticketQuantityFemale" className="mb-1">
              Quantidade de Ingressos Femininos *
            </label>

            <Form.Control
              defaultValue={
                space.allotments[allotmentIndex].ticketQuantityFemale
              }
              onChange={(e) =>
                handleUpdateFieldValue(
                  { ticketQuantityFemale: parseInt(e.target.value) },
                  space._index,
                  allotmentIndex
                )
              }
              type="number"
              placeholder="Ex: 100 ingressos"
            />
          </Col>
        </Row>
      );
    }

    function chooseSpaceComponent(space, allotmentIndex) {
      let el = null;

      switch (genderTicket) {
        case personalGenderOptions[0]:
          el = renderUnissexSpaceComponent(space, allotmentIndex);
          break;
        case personalGenderOptions[1]:
          el = renderDiffValuesSpaceComponent(space, allotmentIndex);
          break;
        default:
          el = "";
      }

      return (
        <>
          <h4 className="mx-auto my-2">{allotmentIndex + 1}º Lote</h4>

          <Row>
            <Col>
              <FloatingLabel
                controlId="floatingInput"
                label="Inicio do Lote *"
                className="mb-3"
              >
                <Form.Control
                  defaultValue={space.allotments[allotmentIndex].startDateTime}
                  onChange={(e) =>
                    handleUpdateFieldValue(
                      {
                        startDateTime: `${e.target.value.replace("T", " ")}:00`,
                      },
                      space._index,
                      allotmentIndex
                    )
                  }
                  type="datetime-local"
                  placeholder="Inicio do Lote *"
                />
              </FloatingLabel>
            </Col>

            <Col>
              <FloatingLabel
                controlId="floatingInput"
                label="Fim do Lote *"
                className="mb-3"
              >
                <Form.Control
                  defaultValue={space.allotments[allotmentIndex].endDateTime}
                  onChange={(e) =>
                    handleUpdateFieldValue(
                      { endDateTime: `${e.target.value.replace("T", " ")}:00` },
                      space._index,
                      allotmentIndex
                    )
                  }
                  type="datetime-local"
                  placeholder="Fim do Lote *"
                />
              </FloatingLabel>
            </Col>

            {!!el ? (
              el
            ) : (
              <div>
                <p className="text-center">
                  Escolha o genêro dos ingressos para poder continuar com os
                  preços e quantidades de ingressos nesse lote.
                </p>
              </div>
            )}
          </Row>
        </>
      );
    }

    function handleUpdateSingleSpaceField(kv = {}, spaceIndex = 0) {
      setSpaces((prev) => {
        let prevSpaces = prev;
        prevSpaces[spaceIndex] = {
          ...prevSpaces[spaceIndex],
          ...kv,
        };

        return [...prevSpaces];
      });
    }

    return (
      <>
        {spaces.map((space) => (
          <Row className="spaces-component mt-4">
            <h5>Ingressos Individuais</h5>
            <p>Lotes criados: {allotmentsCreatedQtty}</p>
            <Col className="mt-3">
              <label htmlFor="eventSpaceName" className="mb-1">
                Area do Evento *
              </label>

              <Form.Control
                defaultValue={space.name}
                onChange={(e) =>
                  handleUpdateSingleSpaceField(
                    { name: e.target.value },
                    space._index
                  )
                }
                type="text"
                placeholder="Ex: Pista Premium"
              />
            </Col>

            <Col className="mt-3">
              <FloatingLabel
                controlId="floatingSelect"
                label="Meia Entrada"
                className="mb-3"
              >
                <Form.Select
                  aria-label="Meia Entrada"
                  defaultValue={space.halfTicketAllowed}
                  onChange={(e) =>
                    handleUpdateSingleSpaceField(
                      {
                        halfTicketAllowed:
                          e.target.value === "true" ? true : false,
                      },
                      space._index
                    )
                  }
                >
                  <option value={false}>Não</option>
                  <option value={true}>Sim</option>
                </Form.Select>
              </FloatingLabel>
            </Col>

            {!!space.allotments ? (
              space.allotments.map((_, allotmentIndex) =>
                chooseSpaceComponent(space, allotmentIndex)
              )
            ) : (
              <small>Algum erro ocorreu!</small>
            )}

            <Button
              className="w-25 mx-auto button-lote"
              onClick={() => {
                setSpaces((prev) => {
                  const newAllotments = space.allotments;
                  if (genderTicket === "Feminino e Masculino") {
                    newAllotments.push({
                      allotmentNumber: space.allotments.length + 1,
                      startDateTime: "",
                      endDateTime: "",
                      ticketQuantityMale: 0,
                      ticketQuantityFemale: 0,
                      ticketPriceMale: 0,
                      ticketPriceFemale: 0,
                    });
                  } else if (genderTicket === "Unissex") {
                    newAllotments.push({
                      allotmentNumber: space.allotments.length + 1,
                      startDateTime: "",
                      endDateTime: "",
                      ticketQuantityUnisex: 0,
                      ticketPriceUnisex: 0,
                    });
                  }

                  let newSpaceState = {
                    ...space,
                    allotments: newAllotments,
                  };

                  let newSpacesList = spaces;
                  newSpacesList[space._index] = newSpaceState;
                  return newSpacesList;
                });

                setAllotmentsCreatedQtty(allotmentsCreatedQtty + 1);
              }}
            >
              Adicionar novo lote
            </Button>
          </Row>
        ))}

        {(ticketsCategory === "Individuais" || ticketsCategory === "Ambos") && (
          <Button
            className="w-75 mx-auto button-espace"
            onClick={() => {
              setSpaces([
                ...spaces,
                {
                  _index: spaces.length,
                  name: "",
                  halfTicketAllowed: false,
                  allotments: [
                    genderTicket === "Feminino e Masculino"
                      ? {
                          allotmentNumber: 1,
                          startDateTime: "",
                          endDateTime: "",
                          ticketQuantityMale: 0,
                          ticketQuantityFemale: 0,
                          ticketPriceMale: 0,
                          ticketPriceFemale: 0,
                        }
                      : genderTicket === "Unissex"
                      ? {
                          allotmentNumber: 1,
                          startDateTime: "",
                          endDateTime: "",
                          ticketQuantityUnisex: 0,
                          ticketPriceUnisex: 0,
                        }
                      : "",
                  ],
                },
              ]);
              setAllotmentsCreatedQtty(allotmentsCreatedQtty + 1);
            }}
          >
            Adicionar espaço
          </Button>
        )}
      </>
    );
  }

  function renderCabinSection() {
    return (
      <>
        {cabins.map((cabin) => (
          <CabinsComponent
            position={cabin._index}
            cabins={cabins}
            setCabins={setCabins}
          />
        ))}

        {(ticketsCategory === "Áreas" || ticketsCategory === "Ambos") && (
          <Button
            className="w-25 mx-auto button-cabine"
            onClick={() => {
              setCabins([
                ...cabins,
                {
                  _index: cabins.length,
                  name: "",
                  ticketQuantity: 0,
                  unitPrice: 0,
                },
              ]);
            }}
          >
            Adicionar cabine
          </Button>
        )}
      </>
    );
  }

  return (
    <main className="stepTwo">
      <hr className="my-4" />

      <form>
        <Row>
          <h4>Ingressos</h4>
          <Col>
            <FloatingLabel
              controlId="floatingInput"
              label="Categoria de Ingressos *"
              className="mb-3"
            >
              <Form.Select
                aria-label="Categoria de Ingressos *"
                defaultValue={ticketsCategory ? ticketsCategory : ""}
                onChange={(e) => {
                  setTicketsCategory(e.target.value);
                  if (e.target.value === "") {
                    setCabins([]);
                    setSpaces([]);
                  } else if (e.target.value === "Áreas") {
                    setSpaces([]);
                    setCabins([
                      {
                        _index: 0,
                        name: "",
                        ticketQuantity: 0,
                        unitPrice: 0,
                      },
                    ]);
                  } else if (e.target.value === "Ambos") {
                    setSpaces([
                      {
                        _index: 0,
                        name: "",
                        halfTicketAllowed: false,
                        allotments: [
                          genderTicket === "Feminino e Masculino"
                            ? {
                                allotmentNumber: 1,
                                startDateTime: "",
                                endDateTime: "",
                                ticketQuantityMale: 0,
                                ticketQuantityFemale: 0,
                                ticketPriceMale: 0,
                                ticketPriceFemale: 0,
                              }
                            : genderTicket === "Unissex"
                            ? {
                                allotmentNumber: 1,
                                startDateTime: "",
                                endDateTime: "",
                                ticketQuantityUnisex: 0,
                                ticketPriceUnisex: 0,
                              }
                            : "",
                        ],
                      },
                    ]);

                    cabins.length === 0 &&
                      setCabins([
                        {
                          _index: 0,
                          name: "",
                          ticketQuantity: 0,
                          unitPrice: 0,
                        },
                      ]);
                  } else if (e.target.value === "Individuais") {
                    setCabins([]);

                    setSpaces([
                      {
                        _index: 0,
                        name: "",
                        halfTicketAllowed: false,
                        allotments: [
                          genderTicket === "Feminino e Masculino"
                            ? {
                                allotmentNumber: 1,
                                startDateTime: "",
                                endDateTime: "",
                                ticketQuantityMale: 0,
                                ticketQuantityFemale: 0,
                                ticketPriceMale: 0,
                                ticketPriceFemale: 0,
                              }
                            : genderTicket === "Unissex"
                            ? {
                                allotmentNumber: 1,
                                startDateTime: "",
                                endDateTime: "",
                                ticketQuantityUnisex: 0,
                                ticketPriceUnisex: 0,
                              }
                            : "",
                        ],
                      },
                    ]);
                  }
                }}
              >
                <option value="">Escolha...</option>
                {ticketCategoryOptions.map((option) => (
                  <option value={option}>{option}</option>
                ))}
              </Form.Select>
            </FloatingLabel>

            {(ticketsCategory === "Individuais" ||
              ticketsCategory === "Ambos") && (
              <FloatingLabel
                controlId="floatingSelect"
                label="Gênero de Ingressos *"
                className="mb-3"
              >
                <Form.Select
                  defaultValue={genderTicket ? genderTicket : ""}
                  onChange={(e) => {
                    setGenderTicket(e.target.value);
                    if (e.target.value === "Feminino e Masculino") {
                      setSpaces([
                        {
                          _index: 0,
                          name: "",
                          halfTicketAllowed: false,
                          allotments: [
                            {
                              allotmentNumber: 1,
                              startDateTime: "",
                              endDateTime: "",
                              ticketQuantityMale: 0,
                              ticketQuantityFemale: 0,
                              ticketPriceMale: 0,
                              ticketPriceFemale: 0,
                            },
                          ],
                        },
                      ]);
                    } else if (e.target.value === "Unissex") {
                      setSpaces([
                        {
                          _index: 0,
                          name: "",
                          halfTicketAllowed: false,
                          allotments: [
                            {
                              allotmentNumber: 1,
                              startDateTime: "",
                              endDateTime: "",
                              ticketQuantityUnisex: 0,
                              ticketPriceUnisex: 0,
                            },
                          ],
                        },
                      ]);
                    }
                  }}
                  aria-label="Gênero de Ingressos *"
                >
                  <option value="">Escolha...</option>
                  {personalGenderOptions.map((option, i) => (
                    <option value={option}>{option}</option>
                  ))}
                </Form.Select>
              </FloatingLabel>
            )}
          </Col>

          <Col>
            <FloatingLabel
              controlId="floatingInput"
              label="Classificação Indicativa *"
              className="mb-3"
            >
              <Form.Select
                aria-label="Classificação Indicativa *"
                defaultValue={ageClassification ? ageClassification : ""}
                onChange={(e) => setAgeClassification(e.target.value)}
              >
                <option>Escolha...</option>
                {ageClassificationOptions.map((option, _) => (
                  <option value={option}>{option}</option>
                ))}
              </Form.Select>
            </FloatingLabel>

            <FloatingLabel
              controlId="floatingInput"
              label="Tag da Festa"
              className="mb-3"
            >
              <Form.Control
                defaultValue={tag ? tag : ""}
                onChange={(e) => setTag(e.target.value)}
                type="text"
                placeholder="Tag da Festa"
              />
            </FloatingLabel>
          </Col>
        </Row>

        <Row>
          {ticketsCategory && <h4 className="mt-4">Montando Lotes</h4>}
          {renderSpaceSection()}
          {renderCabinSection()}
        </Row>
      </form>
    </main>
  );
};
