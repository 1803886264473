import { Col, FloatingLabel, Form, Row, Button } from "react-bootstrap";

export const StepThree = ({
  clientPaysFee,
  setClientPaysFee,
  handleNewEvent,
}) => {
  // EVENT - Step 03
  /* const [paymentMethod, setPaymentMethod] = useState("")
    const [taxes, setTaxes] = useState("")
    const [qrCodeValidation, setQrCodeValidation] = useState(false) */

  return (
    <main className="stepThree">
      <hr className="my-4" />
      <form>
        <Row>
          <Col>
            <h4>Deposito dos valores</h4>
            {/* 
            <Row className="my-4">
              <h6 className="subtitle">D+15</h6>
              <p className="text-muted desc">
                Selecionando os pagamentos pelo evento em até 15 dias resultará
                em um aumento de taxas de 2%. (Opção a ser aplicada futuramente)
              </p>
            </Row> */}

            <Row className="my-4">
              <h6 className="subtitle">D+30</h6>
              <p className="text-muted desc">
                D+30 significa que o tranferência dos valores arrecadados pelo
                evento serão depositados em até 30 dias úteis.
              </p>
            </Row>
          </Col>

          <Col>
            <h4>Taxas</h4>
            <FloatingLabel
              controlId="floatingSelect"
              label="Como deseja prosseguir em relação as taxas? *"
              className="label-taxas"
            >
              <Form.Select
                defaultValue={clientPaysFee ? clientPaysFee : ""}
                onChange={(e) =>
                  setClientPaysFee(
                    e.target.value === "true"
                      ? true
                      : e.target.value === "false"
                      ? false
                      : ""
                  )
                }
                aria-label="Como deseja prosseguir em relação as taxas? *"
              >
                <option value="">Escolha...</option>
                <option value={false}>Assumir taxas</option>
                <option value={true}>Passe a taxa para os participantes</option>
              </Form.Select>
            </FloatingLabel>

            <Row className="my-4">
              <h6 className="subtitle">Resumo das taxas incorridas</h6>
              <p className="text-muted desc">
                Resumo do valor do ingresso (Se assumir) + Valor da taxa, caso
                contrário (Repasse + D+30) 0%.
              </p>
            </Row>

            <Row className="my-4">
              <h6 className="subtitle">Estimativa do evento</h6>
              <p className="text-muted desc">
                Com base no número de ingressos e nos valores dos mesmos, a sua
                estimativa para o evento é:
                <br />
                R$ valor do ingresso X ingressos vendidos
                <br />
                <br />
                Aqui consideramos os valores de todos os lotes assim como a
                quantidade de ingressos totais
              </p>
            </Row>
          </Col>
        </Row>

        <div className="d-flex justify-content-center py-5">
          <Button className="button-create" onClick={() => handleNewEvent()}>
            Criar evento
          </Button>
        </div>
      </form>
    </main>
  );
};
