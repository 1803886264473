import React from "react"
import { Button } from "react-bootstrap"
import { HeaderComponent } from "../../components/header/headerComponent"
import { FooterComponent } from "../../components/footer/footerComponent"
import CardOne from "../../assets/images/group-cards.png"
//import CardTwo from "../../assets/images/card-two.svg"
//import CardThree from "../../assets/images/card-three.svg"
import "./homeView.scss"

export const HomeView = _ => {
    return (
        <main className="home-view w-100">
            <HeaderComponent />
            <article className="content d-flex flex-column justify-content-center align-items-center text-light">
                <h1 className="text-center mb-5 pb-5 px-5">Não espere pelo evento perfeito, crie-o!</h1>
                <Button className="criar-evento d-flex mx-auto mt-5 px-5 py-3 border-0 rounded-4" onClick={() => window.location.assign("event/new")}>Criar evento</Button>
            </article>

            <article className="transition-area d-flex flex-column justify-content-center align-items-center">
                <h2 className="text-light mb-5">Com a Dibba, você pode....</h2>
                <div className="cards">
                    <img alt={"card_one"} src={CardOne} />
                    {/* <img alt={"card_two"} src={CardTwo}/>
                    <img alt={"card_three"} src={CardThree}/> */}
                </div>
            </article>
            <FooterComponent />
        </main>
    )
}