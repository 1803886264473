import axios from "axios";
import { Col, FloatingLabel, Form, Row } from "react-bootstrap";

export const StepOne = ({
  name,
  setName,
  musicalType,
  setMusicalType,
  description,
  setDescription,
  startDateTime,
  setStartDateTime,
  endDateTime,
  setEndDateTime,
  bannerImage,
  setBannerImage,
  bannerImagePath,
  setBannerImagePath,
  cep,
  setCep,
  state,
  setState,
  city,
  setCity,
  addressDescription,
  setAddressDescription,
  address,
  setAddress,
  number,
  setNumber,
  complement,
  setComplement,
  areaDistributionImage,
  setAreaDistributionImage,
  areaDistributionImagePath,
  setAreaDistributionImagePath,
}) => {
  
  const musicalTypeOptions = ["Rock", "Sertanejo", "Trap", "Rap", "Pop", "Eletrônica", "Funk", "Reggae", "Pagode", "Samba", "MPB"]
  
  function onEventBannerInputChange(e) {
    let file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = _handleReaderLoaded.bind(e);
      /* reader.readAsBinaryString(file); */
      reader.readAsDataURL(file);
    }
  }

  function _handleReaderLoaded(e) {
    setBannerImage(e.target.result);
  }

  function onEventDistributionInputChange(e) {
    let file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = _handleReaderLoaded2.bind(e);
      /* reader.readAsBinaryString(file); */
      reader.readAsDataURL(file);
    }
  }

  function _handleReaderLoaded2(e) {
    setAreaDistributionImage(e.target.result);
  }

  const getCEP = (cepInputValue) => {
    // https://h-apigateway.conectagov.estaleiro.serpro.gov.br/api-cep/v1/consulta/cep/60130240
    cepInputValue = cepInputValue.replace("-", "");
    if (cepInputValue.length === 8) {
      axios
        .get(`https://viacep.com.br/ws/${cepInputValue}/json/`)
        .then((res) => {
          setCep(cepInputValue);
          setAddress(res.data.logradouro);
          setCity(res.data.localidade);
          setState(res.data.uf);
        });
    }
  };

  return (
    <main className="stepOne">
      <hr className="my-4" />
      <form>
        <Row xs={1} md={2}>
          <Col>
            <h3 className="mb-3">Detalhes</h3>
            <Row lg={2} xs={1}>
              <Col>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Nome do Evento *"
                  className="mb-3"
                >
                  <Form.Control
                    defaultValue={name ? name : ""}
                    onChange={(e) => setName(e.target.value)}
                    type="text"
                    placeholder="Nome do Evento *"
                  />
                </FloatingLabel>
              </Col>

              <Col>
                <FloatingLabel
                  controlId="floatingSelect"
                  label="Gênero musical *"
                  className="mb-3"
                >
                  <Form.Select
                    defaultValue={musicalType ? musicalType : ""}
                    aria-label="Gênero musical *"
                    onChange={(e) => setMusicalType(e.target.value)}
                  >
                    <option value="">Escolha...</option>
                    {musicalTypeOptions.map((e, _) => (
                      <option value={e}>{e}</option>
                    ))}
                  </Form.Select>
                </FloatingLabel>
              </Col>
            </Row>

            <Row lg={2} xs={1}>
              <Col>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Data de Inicio *"
                  className="mb-3"
                >
                  <Form.Control
                    defaultValue={startDateTime ? startDateTime : ""}
                    onChange={(e) => setStartDateTime(e.target.value.replace('T', ' '))}
                    type="datetime-local"
                    placeholder="Data de Inicio *"
                  />
                </FloatingLabel>
              </Col>

              <Col>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Data de Fim *"
                  className="mb-3"
                >
                  <Form.Control
                    defaultValue={endDateTime ? endDateTime : ""}
                    onChange={(e) => setEndDateTime(e.target.value.replace('T', ' '))}
                    type="datetime-local"
                    placeholder="Data de Fim *"
                  />
                </FloatingLabel>
              </Col>
            </Row>

            <FloatingLabel
              controlId="floatingInput"
              label="Descrição *"
              className="mb-3"
            >
              <Form.Control
                defaultValue={description ? description : ""}
                onChange={(e) => setDescription(e.target.value)}
                type="text"
                placeholder="Descrição *"
                as="textarea"
                style={{ height: "130px" }}
                className="mb-3 description"
              />
            </FloatingLabel>

            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>Imagem do Banner da Festa *</Form.Label>
              <Form.Control
                type="file"
                accept=".jpg, .jpeg, .png"
                //TODO: Contexto -> Ao setar o file, na hora que altera a pagina e volta, buga no value
                onChange={(e) => {
                  onEventBannerInputChange(e);
                  //if (e.target.files[0]) setBannerImagePath(!!e.target.files[0] ? e.target.files[0] : "");
                }}
              />
            </Form.Group>
          </Col>

          <Col>
            <h3 className="mb-3">Local</h3>
            <FloatingLabel label="CEP *" className="mb-3">
              <Form.Control
                defaultValue={cep ? cep : ""}
                onChange={(e) => {
                  setCep(e.target.value);
                  getCEP(e.target.value);
                }}
                type="text"
                placeholder="CEP *"
                maxLength={9}
              />
            </FloatingLabel>

            <Row lg={2} xs={1}>
              <Col>
                <FloatingLabel label="UF *" className="mb-3">
                  <Form.Control
                    defaultValue={state ? state : ""}
                    onChange={(e) => setState(e.target.value)}
                    type="text"
                    placeholder="UF *"
                    disabled
                  />
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel label="Cidade *" className="mb-3">
                  <Form.Control
                    defaultValue={city ? city : ""}
                    onChange={(e) => setCity(e.target.value)}
                    type="text"
                    placeholder="Cidade *"
                    disabled
                  />
                </FloatingLabel>
              </Col>
            </Row>

            <Row lg={2} xs={1}>
              <Col>
                <FloatingLabel label="Nome do Local *" className="mb-3">
                  <Form.Control
                    defaultValue={addressDescription ? addressDescription : ""}
                    onChange={(e) => setAddressDescription(e.target.value)}
                    type="text"
                    placeholder="Nome do Local *"
                    required
                  />
                </FloatingLabel>
              </Col>

              <Col>
                <FloatingLabel label="Rua *" className="mb-3">
                  <Form.Control
                    defaultValue={address ? address : ""}
                    onChange={(e) => setAddress(e.target.value)}
                    type="text"
                    placeholder="Rua *"
                    disabled
                  />
                </FloatingLabel>
              </Col>
            </Row>

            <Row lg={2} xs={1}>
              <Col>
                <FloatingLabel label="Número *" className="mb-3">
                  <Form.Control
                    defaultValue={number ? number : ""}
                    onChange={(e) => setNumber(e.target.value)}
                    type="number"
                    placeholder="Número *"
                    required
                  />
                </FloatingLabel>
              </Col>

              <Col>
                <FloatingLabel label="Complemento" className="mb-3">
                  <Form.Control
                    defaultValue={complement ? complement : ""}
                    onChange={(e) => setComplement(e.target.value)}
                    type="text"
                    placeholder="Complemento"
                  />
                </FloatingLabel>
              </Col>
            </Row>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>Imagem da distribuição de espaços</Form.Label>
              <Form.Control
                type="file"
                accept=".jpg, .jpeg, .png"
                onChange={(e) => {
                  onEventDistributionInputChange(e);
                  //if (e.target.files[0]) setAreaDistributionImagePath(!!e.target.files[0] ? e.target.files[0] : "");
                }}
              />
            </Form.Group>
          </Col>
        </Row>
      </form>
    </main>
  );
};
