import "./accountView.scss";
import { Form, Row, Col, Button, FloatingLabel } from "react-bootstrap";
import { HeaderComponent } from "../../components/header/headerComponent";
import { FooterComponent } from "../../components/footer/footerComponent";
// import IconEdit from "../../assets/images/icon-edit.png";
import AuthService from "../../service/authService";

export const AccountView = (_) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const authService = new AuthService();

  const handleSignOut = () => {
    authService.signOut();
  };

  return (
    <main className="account-view text-light text-center">
      <HeaderComponent />
      <div className="welcome mt-5 pt-5">
        <div className="logout-area">
          <Button
            variant="danger"
            className="logout px-4 py-2 rounded-2 border-0"
            onClick={() => {
              handleSignOut();
            }}
          >
            Sair
          </Button>
        </div>
        <h4 className="fw-light px-2">Olá!</h4>
        <h1 className="m-2 px-4">
          {user.firstName} {user.lastName !== "C" ? user.lastName : ""}
        </h1>
      </div>

      <div className="forms-data w-75 m-auto pt-5">
        <div className="title-forms pt-5 d-flex justify-content-between">
          <p className="data-title">Seus dados</p>
          {/* <img src={IconEdit} alt="Icon edit" className="edit" /> */}
        </div>
        <Row xs={1} md={2}>
          <Col>
            <FloatingLabel
              controlId="floatingInput"
              label="Nome Fantasia"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Nome Fantasia"
                value={
                  user.lastName !== "C"
                    ? ` ${user.firstName + " " + user.lastName}`
                    : user.firstName
                }
                readOnly
              />
            </FloatingLabel>
          </Col>
          <Col>
            <FloatingLabel
              controlId="floatingSelect"
              label="CNPJ"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="CNPJ"
                value={user.docNumber}
                readOnly
              />
            </FloatingLabel>
          </Col>
          <Col>
            <FloatingLabel
              controlId="floatingInput"
              label="E-mail"
              className="mb-3"
            >
              <Form.Control
                type="email"
                placeholder="E-mail"
                value={user.emailAddress}
                readOnly
              />
            </FloatingLabel>
          </Col>
          <Col>
            <FloatingLabel
              controlId="floatingSelect"
              label="Telefone"
              className="mb-3"
            >
              <Form.Control
                type="number"
                placeholder="Telefone"
                value={user.phone}
                readOnly
              />
            </FloatingLabel>
          </Col>
          <Col>
            <FloatingLabel
              controlId="floatingInput"
              label="Data de criação"
              className="mb-3"
            >
              <Form.Control
                type="date"
                placeholder="Data de criação Empresa"
                value={user.birthDate}
                disabled
              />
            </FloatingLabel>
          </Col>
        </Row>
      </div>
      <FooterComponent />
    </main>
  );
};
