import axios from "axios";

let headers = {
  Authorization: "",
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  Accept: "application/json",
};

export const doPost = async (url, body, config) => {
  headers.Authorization = config;
  await axios
    .post(url, body, { headers })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const doGet = (url, config) => {};
