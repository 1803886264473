import "./signInView.scss";
import React, { useState } from "react";
import { Button, FloatingLabel, Form } from "react-bootstrap";
import axios from "axios";
import moment from "moment";
import { useUserContext } from "../../contexts/auth";
import parseJwt from "../../utils/parseJwt";

export const SignInView = (_) => {
  const { setLoggedStatus } = useUserContext();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errMessage, setErrMessage] = useState("");

  function handleSubmit() {
    axios
      .post("https://api.dibba.com.br/v1/auth", {
        emailAddress: email,
        password,
      })
      .then((res) => {
        const userData = parseJwt(res.data.jwtToken)
        if(userData.role === "COMPANY"){
          localStorage.setItem("user", JSON.stringify(userData))
          sessionStorage.setItem("tk", `Bearer ${res.data.jwtToken}`);
          localStorage.setItem("loginDate", moment().format());
          localStorage.setItem("loggedStatusVariable", "true");
          setLoggedStatus(true);
          window.location.assign("/");
        } else{
          alert("Desculpe, não é possível acessar a aba business com contas Costumer")
        }
      })
      .catch((err) => {
        console.log(err);
        setErrMessage(err.response.data.message);
      });
  }

  return (
    <main className="sign-in overflow-hidden d-flex justify-content-center align-items-center">
      <div className="background-auth d-flex justify-content-end align-items-center m-0 rounded-5">
        <form id="form-login" className="text-center d-flex flex-column">
          <h1 className="mb-4">Bem Vindo(a)!</h1>

          <FloatingLabel
            label="E-mail"
            className="mb-3"
          >
            <Form.Control
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              type="text"
              placeholder="E-mail"
              required
              onKeyDown={(e) => {
                if (e.code.toUpperCase() === "ENTER") {
                  handleSubmit();
                }
              }}
            />
          </FloatingLabel>

          <FloatingLabel
            label="Senha"
            className="mb-3"
          >
            <Form.Control
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              type="password"
              placeholder="Senha"
              required
              onKeyDown={(e) => {
                if (e.code.toUpperCase() === "ENTER") {
                  handleSubmit();
                }
              }}
            />
          </FloatingLabel>

          <Button
            variant="dark"
            className="mb-3 border-0"
            id="btn-signin"
            onClick={() => handleSubmit()}
          >
            Entrar
          </Button>

          {errMessage && (
            <p className="text-danger font-weight-bolder mt-3">{errMessage}</p>
          )}

          <small className="d-flex justify-content-between">
            <a className="text-dark" href="/sign-up">Cadastre-se</a>
            <a className="text-dark" href="/recover-password">Esqueceu sua senha?</a>
          </small>
        </form>
      </div>
    </main>
  );
};
