import "./onboardingView.scss";
 import {HeaderComponent} from "../../components/header/headerComponent"
 import ImageTitle from "../../assets/images/image-title.png"
 import AppDibba from "../../assets/images/app-dibba.png"

export const OnboardingView = _ => {
    return (
        <main className="onboarding">
            <article className="header">
                < HeaderComponent />
            </article>

            <div className="title">
                <h1 className="title">Como acompanhar os resultados dos meus eventos?</h1>
                <ul className="title"> <img src={ImageTitle} alt=""/> </ul>
            </div>

            <article className="reports">
                <h1 className="sub-title">Os relatórios são mandados para o e-mail cadastrado.</h1>
                <h3 className="reports">O que são os relatórios?</h3>
                <div className="reports-end">
                    <h5 className="reports">São documentos enviados por email, onde, ao final de um evento realizado pela Dibba, você recebe gráficos e resumos sobre os resultados. Ex:  quantidade de ingressos vendidos por lote, comparação com outros eventos realizado, dentre outras funcionalidades.</h5>
                </div>
            </article>

            <article className="app">
                <div className="texts">
                    <h4 className="marked-text">Controle a entrada do seu evento</h4>
                    <h3 className="app">através do nosso app</h3>
                    <h5 className="app">em todas as plataformas digitais!</h5>
                </div>
                <img alt={"app_dibba"} src={AppDibba} className="app"/>
            </article>
        </main>
    )
}