import "./newEventView.scss";

import { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { doPost } from "../../service/http";
import { useUserContext } from "../../contexts/auth";

import { HeaderComponent } from "../../components/header/headerComponent";
import { StepOne } from "../../components/newEvent/stepOne";
import { StepTwo } from "../../components/newEvent/stepTwo";
import { StepThree } from "../../components/newEvent/stepThree";

export const NewEventView = (_) => {
  const { token } = useUserContext();
  const [step, setStep] = useState(0);

  // First Page
  const [name, setName] = useState();
  const [musicalType, setMusicalType] = useState();
  const [startDateTime, setStartDateTime] = useState();
  const [endDateTime, setEndDateTime] = useState();
  const [description, setDescription] = useState();
  const [bannerImagePath, setBannerImagePath] = useState();
  const [bannerImage, setBannerImage] = useState();
  const [cep, setCep] = useState();
  const [state, setState] = useState();
  const [city, setCity] = useState();
  const [addressDescription, setAddressDescription] = useState();
  const [address, setAddress] = useState();
  const [number, setNumber] = useState();
  const [complement, setComplement] = useState();

  // Second Page
  const [genderTicket, setGenderTicket] = useState();
  const [ageClassification, setAgeClassification] = useState();
  const [ticketsCategory, setTicketsCategory] = useState();
  const [tag, setTag] = useState();
  const [areaDistributionImage, setAreaDistributionImage] = useState();
  const [areaDistributionImagePath, setAreaDistributionImagePath] = useState();
  const [spaces, setSpaces] = useState([]);
  const [cabins, setCabins] = useState([]);

  // Third Page
  const [clientPaysFee, setClientPaysFee] = useState();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const eventFullObject = {
    name: name,
    musicalType: musicalType,
    startDateTime: `${startDateTime}:00`,
    endDateTime: `${endDateTime}:00`,
    description: description,
    bannerImage: bannerImage,
    cep: cep,
    state: state,
    city: city,
    addressDescription: addressDescription,
    address: address,
    number: parseInt(number),
    ...(complement && {
      complement: complement,
    }),
    ...(areaDistributionImage && {
      areaDistributionImage: areaDistributionImage,
    }),
    ageClassification: parseInt(ageClassification),
    ...(tag && {
      tag: tag,
    }),
    ...(spaces.length > 0 && {
      spaces: spaces.map((e) => {
        return {
          name: e.name,
          halfTicketAllowed: e.halfTicketAllowed,
          allotments: e.allotments,
        };
      }),
    }),
    ...(cabins.length > 0 && {
      cabins: cabins.map((e) => {
        return {
          name: e.name,
          ticketQuantity: e.ticketQuantity,
          unitPrice: e.unitPrice,
        };
      }),
    }),
    clientPaysFee: clientPaysFee,
    anticipatedPayment: false,
    qrcodeValidation: true,
    ticketCategories: ["FULL", "HALF", "PARTNER_DISCOUNT"],
  };

  useEffect(() => {
    console.table(eventFullObject);
  }, [eventFullObject]);

  function handleNewEvent() {
    const haveCabinsOrSpaces =
      eventFullObject?.cabins?.length > 0 ||
      eventFullObject?.spaces?.length > 0;

    if (!haveCabinsOrSpaces) {
      alert("Crie pelo menos um camarote ou espaço de ingressos individuais");
      return;
    }

    let emptyField = false;
    const eventEntries = Object.entries(eventFullObject);
    eventEntries.map((entrie) => {
      const [key, value] = entrie;
      if (
        value === "" ||
        value === null ||
        value === undefined ||
        value.length === 0
      ) {
        console.log(key);
        emptyField = true;
        return "";
      }
      return "";
    });

    if (!emptyField) {
      doPost("https://api.dibba.com.br/events", eventFullObject, token);
      return window.location.assign("/event/new/success");
    }
    alert("Algum dos campos obrigatórios do seu evento não foi inserido");
  }

  return (
    <main className="mx-auto new-event-pages">
      <HeaderComponent />
      <div className="modal-background p-5">
        <div>
          <Row className="row-slogan p-3">
            <Col className="d-flex justify-content-center text-center">
              {step === 0 && (
                <p className="slogan-new-event text-center">
                  O primeiro passo para seu evento acontecer...
                </p>
              )}

              {step === 1 && (
                <p className="slogan-new-event">Mais algumas informações...</p>
              )}

              {step === 2 && <p className="slogan-new-event">E por fim...</p>}
            </Col>
          </Row>

          <Row>
            <Col>
              <h2 className="text-white">Etapa {step + 1}/3</h2>
            </Col>

            <Col xs={2}>
              {step >= 1 && (
                <Button
                  variant="outline-dark"
                  className="button-back"
                  onClick={() => {
                    setStep(step - 1);
                  }}
                >
                  Voltar
                </Button>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              {step === 0 && (
                <p className="step-1-text w-50">
                  Para começar, nos conte um pouco sobre as informações do seu
                  evento. lembre-se que essa parte é como ficará visível para o
                  público da nossa plataforma
                </p>
              )}

              {step === 1 && (
                <p>
                  Vamos falar um pouco sobre os lotes x ingressos do seu evento.
                </p>
              )}

              {step === 2 && (
                <p>Para encerrar, precisamos definir a seleção das taxas.</p>
              )}
            </Col>
          </Row>
        </div>

        {step === 0 && (
          <StepOne
            name={name}
            setName={setName}
            musicalType={musicalType}
            setMusicalType={setMusicalType}
            description={description}
            setDescription={setDescription}
            startDateTime={startDateTime}
            setStartDateTime={setStartDateTime}
            endDateTime={endDateTime}
            setEndDateTime={setEndDateTime}
            bannerImage={bannerImage}
            setBannerImage={setBannerImage}
            bannerImagePath={bannerImagePath}
            setBannerImagePath={setBannerImagePath}
            areaDistributionImage={areaDistributionImage}
            setAreaDistributionImage={setAreaDistributionImage}
            areaDistributionImagePath={areaDistributionImagePath}
            setAreaDistributionImagePath={setAreaDistributionImagePath}
            cep={cep}
            setCep={setCep}
            state={state}
            setState={setState}
            city={city}
            setCity={setCity}
            addressDescription={addressDescription}
            setAddressDescription={setAddressDescription}
            address={address}
            setAddress={setAddress}
            number={number}
            setNumber={setNumber}
            complement={complement}
            setComplement={setComplement}
          />
        )}
        {step === 1 && (
          <StepTwo
            genderTicket={genderTicket}
            setGenderTicket={setGenderTicket}
            ageClassification={ageClassification}
            setAgeClassification={setAgeClassification}
            ticketsCategory={ticketsCategory}
            setTicketsCategory={setTicketsCategory}
            tag={tag}
            setTag={setTag}
            spaces={spaces}
            setSpaces={setSpaces}
            cabins={cabins}
            setCabins={setCabins}
          />
        )}
        {step === 2 && (
          <StepThree
            clientPaysFee={clientPaysFee}
            setClientPaysFee={setClientPaysFee}
            handleNewEvent={() => handleNewEvent()}
          />
        )}
        {step <= 1 && (
          <Button
            className="w-25 py-2 mt-4 d-block ms-auto text-white border-0 rounded-3 button-next"
            onClick={() => {
              setStep(step + 1);
            }}
          >
            Avançar
          </Button>
        )}
      </div>
    </main>
  );
};
