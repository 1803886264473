import "./index.css";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import { useEffect } from "react";
import moment from "moment";
import { useUserContext } from "./contexts/auth";

import { HomeView } from "./views/home/homeView";
import { SignInView } from "./views/sign-in/signInView";
import { SignUpView } from "./views/sign-up/signUpView";
import { RecoverPasswordView } from "./views/recover-password/recoverPasswordView";
import { OnboardingView } from "./views/onboarding/onboardingView";
// import { DashboardView } from "./views/dashboard/dashboardView";
import { NewEventView } from "./views/new-event/newEventView";
import { NewEventSuccessView } from "./views/new-event-success/newEventSuccessView";
import { AccountView } from "./views/account/accountView";

function AppRouter() {
  const { loggedStatus, setLoggedStatus } = useUserContext();

  useEffect(() => {
    const date = localStorage.getItem("loginDate");
    if (date === null) {
      localStorage.clear();
      sessionStorage.clear();
      setLoggedStatus(false);
    }

    const dateDiff = moment().diff(date, "hours");
    if (dateDiff >= 2) {
      localStorage.clear();
      sessionStorage.clear();
      setLoggedStatus(false);
    }
  });

  if (loggedStatus) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path={"/"} element={<HomeView />} />
          <Route path={"/event/new"} element={<NewEventView />} />
          <Route path={"/event/new/success"} element={<NewEventSuccessView />} />
          <Route path={"/onboarding"} element={<OnboardingView />} />
          <Route path={"/account"} element={<AccountView />} />
          {/* <Route path={"/dashboard"} element={<DashboardView />} /> */}
        </Routes>
      </BrowserRouter>
    );
  } else {
    return (
      <BrowserRouter>
        <Routes>
          <Route path={"/"} element={<SignInView />} />
          <Route path={"/sign-up"} element={<SignUpView />} />
          <Route path={"/recover-password"} element={<RecoverPasswordView />} />
        </Routes>
      </BrowserRouter>
    );
  }
}

export default AppRouter;
