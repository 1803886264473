export default class AuthService {
  // constructor(event) {
  //   this.event = event;
  // }

  signOut() {
    localStorage.clear()
    sessionStorage.clear()
    window.location.assign("/")
  }
}

// const new_event_empty = {
//   name: "",
//   description: "",
//   tag: "",
//   startDateTime: "",
//   endDateTime: "",
//   cep: "",
//   address: "",
//   number: "",
//   ageClassification: "",
//   state: "",
//   areaDistributionImage: "",
//   bannerImage: "",
//   musicalType: "",
//   city: "",
//   clientPaysFee: "",
//   anticipatedPayment: true,
//   qrcodeValidation: "" /* INSERIR FRONT */,
//   spaces: [],
//   cabins: [],
//
//   /* genderTickets */
//   /* categoryTickets */
// };
